import { Component, Vue } from 'vue-property-decorator'

// ==============================
// === ドリル開始API

// ドリル開始時の条件指定 パターンが増えた場合は拡張する
export type TargetCurriculum = {
  curriculumSUnitId?: number
}
// ドリル開始時のレスポンス
type ResponseStartDrill = {
  resultDrillId: number
  currentPage: number
}

// ==============================
// === 問題取得API

// 問題取得のレスポンス
export type ResponseStudyProblem = {
  currentPage: number
  subjectCode: string
  classModeCode: string
  classCategoryCode: string
  endConditions: EndCondition
  isFinished: boolean
  sProblemIds: number[] | null // 出題対象が存在しない場合はnull
}

// ==============================
// === 問題情報API

// 問題情報のレスポンス(1大問分)
export type ResponseProblem = {
  isLProblem: boolean
  subjectCode: string
  answerFormat: number
  problems: SProblem[]
}

export type ResponseOneQuestion = {
  isLProblem: boolean
  subjectCode: string
  answerFormat: number
  problems: SProblem
}

// 問題情報のレスポンス(1小問分)
export type SProblem = {
  sProblemId: number
  sProblemCode: string
  number: number
  images: ImagePaths
  existMovie: boolean
  questions: ResponseQuestion[]
}

// 問題に対する画像パス群
export type ImagePaths = {
  d?: string[] // 読解文 配列で複数保持
  m?: string[] // 問題 配列で複数保持
  t?: string[] // 解答 配列で複数保持
  s?: string[] // 解説 配列で複数保持
  j?: string // 指示文 文字列で単体保持
}

// 問題情報のレスポンス(1設問分)
export type ResponseQuestion = {
  questionCode: string
  managementLabel: string | null
  timeLimit: number | null
  contents: ResponseQuestionContent[]
  images: ImagePaths
}

// ==============================
// === 正誤入力情報取得API

// 正誤入力情報取得のレスポンス
export type ResponseResultProblem = {
  resultDrillId: number
  classModeCode: string
  classCategoryCode: string
  endConditions: EndCondition
  isCorrect: boolean
  pageNum: number
  isFinished: boolean
  problems: ResultProblem[]
  currentPage: number
  curriculumSUnitId: number
  userDrillCode?: string
  classModeId: number
  classCategoryId: number
}

type EndCondition = {
  restriction: string
  left: number
}

// 大問実施結果
export type ResultProblem = {
  sProblemId: number
  sProblemCode: string
  isMoviePlayed?: boolean
  questions: ResultQuestion[]
}

// 設問実施結果
export type ResultQuestion = {
  answerFormatCode?: string
  questionCode: string
  correct: boolean | null
  answers?: boolean | null
  ocrCorrect: boolean | null
  isFirstTimeTarget?: boolean
}

type RequestSaveMemo = {
  result_drill_id: string
  page_num: string
  memo_img: File
  question_code: string
  memo_coordinates?: string
}

type ParamsGetMemoInfo = {
  result_drill_id: string
  page_num: string
  question_code: string
}

type RequestSubmitAllAnswer = {
  result_drill_id: string
  page_num: string
  ocr_data: { image: File; question_code: string; question_answer: string; number: string; done: boolean, student_text_answer: string, candidates: string }[]
}

type ResponseQuestionContent = {
  answerFormatCode: string
  labelText?: string | null
  beforeText?: string | null
  afterText?: string | null
  choiceText?: string | null
  sortNum?: number | null
  correctInputText?: string | null
  isCorrectText?: boolean | null
  correctSort?: number | null
}
// ==============================
// === 動画取得API

// 解説動画取得リクエスト
type RequestProblemMovie = {
  sProblemId: number
  resultDrillId: number
  movieType: string
}

// 導入動画取得リクエスト
type RequestIntroductionMovie = {
  curriculumSUnitId: number
  movieType: string
}

// 動画取得結果
type ResponseProblemMovie = {
  movies: MovieData[]
  curriculumSUnitName: string
}

// 動画情報
export type MovieData = {
  movieId: number
  movieName: string
  movieFilename: string
  movieUrl: string
  sortNum: number
  liked: boolean
  watched: boolean
}

@Component
export default class WebApi extends Vue {
  /**
   * レスポンスコードから「削除済み」を判定する
   *
   * @param status ステータスコード(204)が削除済み
   * @returns 判定結果
   */
  protected statusDeleted(status: number): boolean {
    return status === 204
  }

  /**
   * ドリル開始Web API
   *
   * @param studyLogicType 学習ロジック('MN', 'AI')
   * @param classModeCode 授業モード
   * @param classCategoryCode ドリル種別
   * @param targetCurriculum 開始条件指定 内部の値はモードなどにより不定
   *
   * @returns ドリル結果を格納するテーブルとページ番号が返る
   */
  protected async startDrillApi(
    studyLogicType: string,
    classModeCode: string,
    classCategoryCode: string,
    targetCurriculum: TargetCurriculum,
    homeworkSettingItemId?: string | number
  ): Promise<ResponseStartDrill> {
    const params: any = {
      studyLogicType: studyLogicType,
      classModeCode: classModeCode,
      classCategoryCode: classCategoryCode,
      targetCurriculum: targetCurriculum,
    }
    if (homeworkSettingItemId) {
      params.homework_setting_item_id = homeworkSettingItemId
    }

    Vue.prototype.$logger.info('-- WebApi startDrill')
    Vue.prototype.$logger.info(params)

    const result = await Vue.prototype.$http.httpWithToken.post('/v3/drills/start', params)
    return result.data
  }

  /**
   * ドリル開始Web API
   *
   * @param studyLogicType 学習ロジック('MN', 'AI')
   * @param classModeCode 授業モード
   * @param classCategoryCode ドリル種別
   * @param targetCurriculum 開始条件指定 内部の値はモードなどにより不定
   *
   * @returns ドリル結果を格納するテーブルとページ番号が返る
   */
  protected async startDrillApiRandom(
    studyLogicType: string,
    classModeCode: string,
    classCategoryCode: string,
    subjectId: number,
    targetCurriculum: TargetCurriculum
  ): Promise<ResponseStartDrill> {
    const params = {
      studyLogicType: studyLogicType,
      classModeCode: classModeCode,
      classCategoryCode: classCategoryCode,
      subjectId: subjectId,
      targetCurriculum: targetCurriculum,
    }
    Vue.prototype.$logger.info('-- WebApi startDrill')
    Vue.prototype.$logger.info(params)

    const result = await Vue.prototype.$http.httpWithToken.post('/v3/drills/start', params)
    return result.data
  }

  /**
   * ドリル完了API
   *  途中でドリルを終了する際に実行する
   *
   * @param resultDrillId ドリルID
   * @returns 処理結果ステータス
   */
  protected async completeDrillApi(resultDrillId: number): Promise<number> {
    // パラメータ生成
    const params = {
      resultDrillId: resultDrillId,
    }

    Vue.prototype.$logger.info('-- WebApi completeDrill')
    Vue.prototype.$logger.info(params)

    const result = await Vue.prototype.$http.httpWithToken.post('/v3/drills/complete', params)
    return result.status
  }

  /**
   * 問題取得API
   *  演習対象の問題を取得する(1大問分)
   *
   * @params resultDrillId ドリルID
   * @returns 出題情報
   */
  protected async getStudyProblems(resultDrillId: number): Promise<ResponseStudyProblem> {
    // パラメータ生成
    const params = {
      resultDrillId: resultDrillId,
    }

    Vue.prototype.$logger.info('-- WebApi getStudyProblems')
    Vue.prototype.$logger.info(params)

    const result = await Vue.prototype.$http.httpWithToken.get('/v3/studyProblems', {
      params: params,
    })

    return result.data
  }

  protected async getStudyProblemsBookmarkRetry(resultDrillId: number, questionCode: string): Promise<ResponseStudyProblem> {
    // パラメータ生成
    const params = {
      result_drill_id: resultDrillId,
      question_code: questionCode
    }

    Vue.prototype.$logger.info('-- WebApi getStudyProblems')
    Vue.prototype.$logger.info(params)

    const result = await Vue.prototype.$http.httpWithToken.get('/v3/question_bookmarks/drills/study_problems', {
      params: params,
    })

    return result.data
  }

  protected async getStudyProblemsSimilar(resultDrillId: number, questionCode: string): Promise<ResponseStudyProblem> {
    // パラメータ生成
    const params = {
      result_drill_id: resultDrillId,
      question_code: questionCode
    }

    Vue.prototype.$logger.info('-- WebApi getStudyProblems')
    Vue.prototype.$logger.info(params)

    const result = await Vue.prototype.$http.httpWithToken.get('/v3/similar_questions/drills/study_problems', {
      params: params,
    })

    return result.data
  }

  /**
   * 問題情報取得API
   *  画面表示に使用する問題情報を取得する(1大問)
   *
   * @param sProblemIds 小問IDの配列(大問単位)
   * @retuen 画面表示用レスポンス(1件)
   */
  protected async getProblem(sProblemIds: number[]): Promise<ResponseProblem> {
    const problems = await this.getProblems([sProblemIds])
    return problems[0]
  }

  /**
   * 問題情報取得API
   *  画面表示に使用する問題情報を取得する(複数大問)
   *
   * @param sProblemIds 小問IDの二次元配列(1項目は大問単位)
   * @retuen 画面表示用レスポンス(複数)
   */
  protected async getProblems(sProblemIds: number[][], version = 'v3', otherParams?: {
    question_code?: string;
    result_drill_id?: number
  }): Promise<ResponseProblem[]> {
    // パラメータ生成
    const params = {
      sProblemIds: JSON.stringify(sProblemIds),
      ...(otherParams || {})
    }

    Vue.prototype.$logger.info('-- WebApi getProblems')
    Vue.prototype.$logger.info(params)

    const result = await Vue.prototype.$http.httpWithToken.get(`/${version}/problems`, {
      params: params,
    })

    this.$store.dispatch('drillsV3/questionContents', result.data)
    return result.data
  }

  /**
   * 正誤入力情報取得API
   *
   * @params resultDrillId ドリルID
   * @oarams page 対象ページ
   * @returns 正誤情報
   */
  protected async getResultProblem(resultDrillId: number, page: number, otherParams?: {
    question_code: string
  }): Promise<ResponseResultProblem> {
    // パラメータ生成
    const params = {
      resultDrillId: resultDrillId,
      pageNum: page,
      ...(otherParams || {})
    }

    Vue.prototype.$logger.info('-- WebApi getResultProblem')
    Vue.prototype.$logger.info(params)

    const result = await Vue.prototype.$http.httpWithToken.get('/v3/resultProblems', {
      params: params,
    })
    return result.data
  }

  /**
   * 正誤情報登録(ページ単位)API
   *
   * @params resultDrillId ドリルID
   * @params page 対象ページ
   * @params corrects 正誤情報
   * @returns 正誤情報
   */
  protected async registCorrectUnits(resultDrillId: number, page: number, corrects: (boolean | null)[]) {
    // パラメータ生成
    const params = {
      resultDrillId: resultDrillId,
      pageNum: page,
      corrects: corrects,
    }

    Vue.prototype.$logger.info('-- WebApi registCorrectUnits')
    Vue.prototype.$logger.info(params)

    await Vue.prototype.$http.httpWithToken.patch('/v3/resultProblems/unit', params)
  }

  /**
   * 正誤情報登録(ページ単位)API
   *
   * @params resultDrillId ドリルID
   * @params page 対象ページ
   * @params corrects 正誤情報
   * @returns 正誤情報
   */
  protected async registCorrectUnitsV3(resultDrillId: number, page: number, input: any[]) {
    // パラメータ生成
    const params = {
      resultDrillId: resultDrillId,
      pageNum: page,
      input: input,
    }

    Vue.prototype.$logger.info('-- WebApi registCorrectUnits')
    Vue.prototype.$logger.info(params)

    await Vue.prototype.$http.httpWithToken.patch('/v3/resultProblems/unit', params)
  }
  /**
   * 解説動画取得
   *
   * @param sProblemId 問題ID
   * @param resultDrillId ドリルID
   * @returns 動画取得結果
   */
  protected async getExplainMovies(
    sProblemId: number,
    resultDrillId: number,
    version = 'v3'
  ): Promise<ResponseProblemMovie> {
    // リクエスト定義(動画タイプは固定)
    const requestProblemMovie: RequestProblemMovie = {
      sProblemId: sProblemId,
      resultDrillId: resultDrillId,
      movieType: 'k',
    }

    Vue.prototype.$logger.info('-- WebApi getExplainMovies')
    Vue.prototype.$logger.info(requestProblemMovie)

    const result = await Vue.prototype.$http.httpWithToken.get(`/${version}/sProblemsMovies`, {
      params: requestProblemMovie,
    })

    const resultData = {
      curriculumSUnitName: result.data.meta.curriculumSUnitName,
      movies: result.data.movies,
    }
    return resultData
  }

  /**
   * 導入動画取得
   *
   * @param curriculumSUnitId 小項目ID
   * @returns 動画取得結果
   */
  protected async getIntroductionMovies(curriculumSUnitId: number): Promise<ResponseProblemMovie> {
    // リクエスト定義(動画タイプは固定)
    const requestIntroductionMovie: RequestIntroductionMovie = {
      curriculumSUnitId: curriculumSUnitId,
      movieType: 'd',
    }

    Vue.prototype.$logger.info('-- WebApi getIntroductionMovies')
    Vue.prototype.$logger.info(requestIntroductionMovie)

    const result = await Vue.prototype.$http.httpWithToken.get('/v3/curriculumSUnitMovies', {
      params: requestIntroductionMovie,
    })

    const resultData = {
      curriculumSUnitName: result.data.meta.curriculumSUnitName,
      movies: result.data.movies,
    }
    return resultData
  }

  /**
   * s3の画像URLを取得
   *
   * @param path s3パス
   * @returns s3アクセス用フルパス
   */
  protected async getImageUrl(
    path: string,
    other?: {
      result_question?: boolean
    }
  ): Promise<string> {
    const result = await Vue.prototype.$http.httpWithToken.get('/v3/imageUrl', {
      params: { path: path, result_question: other?.result_question },
    })
    return result.data.url
  }
  //現在ページの解答入力情報を保持するcookieキー(正誤結果の送信時に使用する)
  private DRILL_CURRENT_PAGE_INPUT_COOKIE = 'gdlsCurrentPageInput'

  /**
   * 現在ページの解答入力情報をcookieに一時保存(セッション中のみ保持)
   *
   * @param input 入力情報
   */
  protected async setCurrentPageInput(input: any[]): Promise<void> {
    await Vue.prototype.$cookies.set(this.DRILL_CURRENT_PAGE_INPUT_COOKIE, JSON.stringify(input), 0)
  }

  /**
   * 現在ページの解答入力情報を取得
   *
   * @returns 入力情報
   */
  protected async getCurrentPageInput(): Promise<any[]> {
    return JSON.parse(await Vue.prototype.$cookies.get(this.DRILL_CURRENT_PAGE_INPUT_COOKIE))
  }

  protected async saveAllAnswer(body: RequestSubmitAllAnswer) {
    const formData = new FormData()

    // Append the scalar values to the FormData
    formData.append('result_drill_id', body.result_drill_id)
    formData.append('page_num', body.page_num)

    // Append the image files and question codes to the FormData
    body.ocr_data.forEach((item, index) => {
      formData.append(`ocr_data[${index}][image]`, item.image)
      formData.append(`ocr_data[${index}][question_code]`, item.question_code)
      formData.append(`ocr_data[${index}][question_answer]`, item.question_answer)
      formData.append(`ocr_data[${index}][number]`, item.number);
      formData.append(`ocr_data[${index}][done]`, item.done as any)
      formData.append(`ocr_data[${index}][student_text_answer]`, item.student_text_answer as any)
      formData.append(`ocr_data[${index}][candidates]`, item.candidates as any)
    })

    const result = await Vue.prototype.$http.httpWithToken.post('/v3/resultProblems/unit_ocr_score', formData)

    return result?.data
  }

  protected async saveMemo(body: RequestSaveMemo) {
    const formData = new FormData()

    Object.keys(body).forEach((key) => {
      formData.append(key, body[key])
    })

    const result = await Vue.prototype.$http.httpWithToken.post('/v3/question_memos', formData)

    return result?.data
  }

  protected async saveMemoV2(body: RequestSaveMemo) {
    const formData = new FormData()

    Object.keys(body).forEach((key) => {
      formData.append(key, body[key])
    })

    const result = await Vue.prototype.$http.httpWithToken.post('/v3/question_memos_v2', formData)

    return result?.data
  }

  protected async getMemoInfo(params: ParamsGetMemoInfo) {
    const result = await Vue.prototype.$http.httpWithToken.get('/v3/question_memos/imageUrl', { params })

    return result?.data
  }

  protected async finishRegisterUnits(resultDrillId: number, page: number, corrects: (boolean | null)[], otherParams?: {
    question_code: string
  }) {
    // パラメータ生成
    const params = {
      resultDrillId: resultDrillId,
      pageNum: page,
      corrects: corrects,
      ...(otherParams || {})
    }

    Vue.prototype.$logger.info('-- WebApi registCorrectUnits')
    Vue.prototype.$logger.info(params)

    await Vue.prototype.$http.httpWithToken.post('/v3/resultProblems/finish_register_unit', params)
  }

  protected async getOneQuestions(questionCode: string, resultDrillId: number): Promise<ResponseOneQuestion> {
    // パラメータ生成
    const params = {
      question_code: questionCode,
      result_drill_id: resultDrillId
    }

    const result = await Vue.prototype.$http.httpWithToken.get(`v3/question_bookmarks/drills/problems`, {
      params: params,
    })
    return result.data
  }

  protected async getOneSimilarQuestions(questionCode: string, resultDrillId: number): Promise<ResponseOneQuestion> {
    // パラメータ生成
    const params = {
      question_code: questionCode,
      result_drill_id: resultDrillId
    }

    const result = await Vue.prototype.$http.httpWithToken.get(`v3/similar_questions/drills/problems`, {
      params: params,
    })
    return result.data
  }
}
